<template>
  <div>
    <v-row v-for="(registro, index) in carro" :key="index">
      <v-col cols="12" class="pt-0 ">
        <v-alert
            class="ma-0"
            text
            color="primary"
            icon="mdi-file"
        >
          <span>{{ registro.tipo_registro }}</span>
        </v-alert>
        <v-alert
            color="cyan"
            outlined
            class="mt-4"
            type="success"
            :icon="false"
        >
          <v-card flat>
            <v-card-text>
              <div v-for="grupo in registro.grupos_documentos" :key="grupo.id_area_agrupacion" class="pb-5">
                <div
                    v-if="registro.id_tipo_registro !== ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION && registro.id_tipo_registro !== ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS &&  registro.id_tipo_registro !== ID_TIPO_REGISTRO.INSCRIPCION_PROPIEDAD &&  registro.id_tipo_registro !== ID_TIPO_REGISTRO.INSCRIPCION_COMERCIO">
                  <item-carro :grupo="grupo" @deleteItemCarro="eliminarCertificado($event)"
                              @aumentarCantidad="aumentarCantidad($event)"
                              @reducirCantidad="reducirCantidad($event)"></item-carro>
                </div>
                <div
                    v-if="registro.id_tipo_registro === ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION || registro.id_tipo_registro === ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS || registro.id_tipo_registro === ID_TIPO_REGISTRO.INSCRIPCION_PROPIEDAD  || registro.id_tipo_registro === ID_TIPO_REGISTRO.INSCRIPCION_COMERCIO">
                  <item-carro-alzamiento :grupo="grupo" @deleteItemCarro="eliminarCertificado($event)"
                                         @aumentarCantidad="aumentarCantidad($event)"
                                         @reducirCantidad="reducirCantidad($event)"></item-carro-alzamiento>
                </div>

                <!--                <v-textarea counter="1000" class="mt-3"-->
                <!--                            v-model="grupo.observaciones"-->
                <!--                            label="Observaciones"-->
                <!--                            outlined dense></v-textarea>-->
              </div>
              <v-row v-if="registro.id_tipo_registro == 24 && registro.grupos_documentos[0].certificados.length >1">
                <v-col>
                  <v-alert outlined color="warning" >Estimado requirente, de momento no es posible concretar su compra con más de un ítem de Estudio de Título en el carro.</v-alert>
                </v-col>
              </v-row>
              <v-row class="d-none d-sm-flex" v-if="registro.alzamiento != null">
                <v-col cols="5">
                  <p class="pl-2 text--black mb-1"><span class="font-weight-medium">Notario titular:</span>
                    {{ registro.alzamiento.notaria }}</p>
                  <p class="pl-2 text--black mb-0"><span class="font-weight-medium">Código de verificación:</span>
                    {{ registro.alzamiento.id_documento_electronico }}</p>
                </v-col>
                <v-col cols="4">
                  <p class="pl-2 text--black mb-1"><span class="font-weight-medium">Repertorio:</span>
                    {{ registro.alzamiento.repertorio }}</p>
                  <p class="pl-2 text--black mb-0"><span class="font-weight-medium">Partes:</span>
                    {{ registro.alzamiento.partes }}</p>
                </v-col>
                <v-col cols="3" class="text-right d-flex align-center justify-end">
                  <h3 class="pr-3">Subtotal:
                    <span v-if="registro.subtotal > 0"> {{ registro.subtotal | clp }}</span>
                    <span v-if="registro.subtotal === 0">Por cotizar</span>
                  </h3>
                </v-col>
              </v-row>

              <v-row class="d-flex d-sm-none" v-if="registro.alzamiento != null">
                <v-col cols="12" class="px-0 pt-0">
                  <p class="pl-2 text--black mb-2"><span class="font-weight-medium">Notario titular:</span>
                    {{ registro.alzamiento.notaria }}</p>
                  <v-divider></v-divider>
                  <p class="pl-2 text--black my-2"><span class="font-weight-medium">Código de verificación:</span>
                    {{ registro.alzamiento.id_documento_electronico }}</p>
                  <v-divider></v-divider>
                  <p class="pl-2 text--black my-2"><span class="font-weight-medium">Repertorio:</span>
                    {{ registro.alzamiento.repertorio }}</p>
                  <v-divider></v-divider>
                  <p class="pl-2 text--black mt-2 mb-0"><span class="font-weight-medium">Partes:</span>
                    {{ registro.alzamiento.partes }}</p>
                </v-col>
                <v-col cols="12" class="text-right d-flex align-center justify-end">
                  <h3 class="pr-0">
                    Subtotal: <span v-if="registro.subtotal > 0"> {{ registro.subtotal | clp }}</span>
                    <span v-if="registro.subtotal === 0">Por cotizar</span>
                  </h3>
                </v-col>
              </v-row>

              <v-row class="d-none d-sm-flex" v-if="registro.datos_adicionales != null">
                <v-col cols="8">
                  <v-row>
                    <v-col cols="6" class="py-0" v-for="(da, i) in registro.datos_adicionales" :key="`da-${i}`">
                      <p class="pl-2 text--black mb-1">
                          <span class="font-weight-medium pr-1">{{ da.nombre | cleanDatoAdicional }}:</span>
                        <span v-if="da.nombre.toLowerCase() != 'fecha'">{{ da.valor | cleanDatoAdicionalF2890 }}</span>
                        <span v-if="da.nombre.toLowerCase() == 'fecha'">{{ da.valor | toDate }}</span>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4" class=" pr-0 text-right d-flex align-center justify-end">
                  <h3 class="pr-3">Subtotal: <span v-if="registro.subtotal > 0"> {{ registro.subtotal | clp }}</span>
                    <span v-if="registro.subtotal === 0">Por cotizar</span></h3>
                </v-col>

              </v-row>

              <v-row class="d-flex d-sm-none" v-if="registro.datos_adicionales != null">

                <v-col cols="12" class="py-0" v-for="(da, i) in registro.datos_adicionales" :key="`da-${i}`">
                  <p class="pl-2 text--black mb-1"><span class="font-weight-medium">{{ da.nombre }}: </span>
                    <span v-if="da.nombre !== 'Capital'">{{ da.valor }}</span>
                    <span v-if="da.nombre === 'Capital'">{{ da.valor | clp }}</span></p>
                </v-col>
              </v-row>

              <v-row v-if="registro.alzamiento == null && registro.datos_adicionales == null">
                <v-col cols="12" class="text-right pt-0 pt-sm-3">
                  <h3 class="pr-sm-3">Subtotal: <span v-if="registro.subtotal > 0"> {{ registro.subtotal | clp }}</span>
                    <span v-if="registro.subtotal === 0">Por cotizar</span></h3>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="carro.length === 0">
      <v-col cols="12" class="pt-2">
        <v-alert
            text
            :icon="false"
            type="info"
            class="pt-2 pb-3"
        >
          <div class="text-center d-flex align-center justify-center">
            <v-icon color="info" class="pr-1">mdi-information-outline</v-icon>
            <p class="mb-0  pt-1"> El carro está vacío, presione <a
                class="font-weight-bold blue--text px-1" @click="irATramites()">aquí </a> para comenzar a agregar
              documentos</p>

          </div>

        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-right pt-0" v-if="carro.length > 0">
        <h3 class="pr-2">Total:
          <span v-if="getTotalCarro > 0">{{ getTotalCarro | clp }}</span>
          <span class="font-weight-medium" v-if="getTotalCarro === 0">Por cotizar</span>
        </h3>
      </v-col>
      <v-col cols="12" class="text-right pt-0">
        <v-btn
            class="ma-2"
            :dark="carro.length > 0"
            color="red"
            @click="verModalVaciarCarro = true"
            :disabled="carro.length == 0"
        >
          <v-icon small class="pr-1">mdi-trash-can-outline</v-icon>
          Vaciar Carro
        </v-btn>
        <v-btn
            class="ma-2"
            color="primary"
            :disabled="soloUnEstudioDeTituloBoolean"
            @click="modalPago"
        >
          <v-icon small class="pr-1">mdi-credit-card</v-icon>
          <span v-if="getTotalCarro === 0">Cotizar</span>
          <span v-if="getTotalCarro > 0">Comprar</span>

        </v-btn>
      </v-col>
    </v-row>

    <simple-modal tipo="error" :ver-dialog="verModalVaciarCarro" @ocultarDialog="verModalVaciarCarro = false">
      <div>
        <h3 class="error--text font-weight-medium text-center pb-1">Vaciar el carro</h3>
        <v-row>
          <v-col cols="3" class="d-flex align-center justify-center">
            <v-icon color="error" x-large>mdi-trash-can-outline</v-icon>
          </v-col>
          <v-col cols="9">¿Está seguro de que desea eliminar todos los elementos de su carro de compras?</v-col>
          <v-col cols="6" class="pl-6 pt-4">
            <v-btn color="info" outlined block @click="verModalVaciarCarro = false">No eliminar</v-btn>
          </v-col>
          <v-col cols="6" class="pr-6 pt-4">
            <v-btn color="error" outlined block @click="vaciarCarro">Si, eliminar carro</v-btn>
          </v-col>

        </v-row>
      </div>
    </simple-modal>
    <modal-pago :lista-observaciones="getListaObservaciones" :ver-dialog="verModalPago" :subtotal="getTotalCarro"
                @ocultarDialog="verModalPago = false"
                @updateCarro="updateCarro($event)" @generandoCarro="generandoCarro"
                :has_por_cotizar="hasDocumentosPorCotizar"></modal-pago>
    <modal-usuario-no-verificado :ver-dialog="verModalActivacion"
                                 @ocultarDialog="verModalActivacion = false"></modal-usuario-no-verificado>
    <dialog-term-cond :ver-dialog="verModalTerminosYCondiciones"
                      @ocultarDialog=" verModalTerminosYCondiciones = false"></dialog-term-cond>
  </div>

</template>

<script>


import carroEntity from "@/model/carro/carro.entity";
import registrosEntity, {ID_TIPO_CERTIFICADO, ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import ItemCarro from "@/views/index/carro/ItemCarro";
import ItemCarroAlzamiento from "@/views/index/carro/ItemCarroAlzamiento";
import SimpleModal from "@/components/SimpleModal";
import usuarioEntity from "@/model/usuario/usuario.entity";
import ModalPago from "@/views/index/carro/modal-pago/ModalPago";
import {EventBus} from "@/main";
import ModalUsuarioNoVerificado from "@/views/index/carro/modalUsuarioNoVerificado";
import DialogTermCond from "@/components/Dialogs/DialogTermCond";
import {ID_TIPO_TRAMITE} from "@/model/tramites/tramites.entity";

export default {
  name: "CarroViewLista",
  components: {
    DialogTermCond,
    ModalUsuarioNoVerificado, ModalPago, SimpleModal, ItemCarroAlzamiento, ItemCarro
  },
  data() {
    return {
      lista_carro: this.getCarroActual,
      carro: carroEntity.obtenerCarroActualCategorizado(),
      total: 0,
      ID_TIPO_REGISTRO: registrosEntity.ID_TIPO_REGISTRO,
      verModalVaciarCarro: false,
      verModalPago: false,
      verModalActivacion: false,
      verModalTerminosYCondiciones: false
    }

  },


  created() {
    this.lista_carro = carroEntity.obtenerCarroActual();
  },
  methods: {
    generandoCarro() {
      this.$emit("generandoCarro", null);
    },
    modalPago() {

      usuarioEntity.isSessionActive().then(resp => {
        if (resp) {
          if (!usuarioEntity.verificarVersionTerminosCondiciones()) {
            this.verModalTerminosYCondiciones = true;
            return;
          }

          if (usuarioEntity.getEstadoActivacionCliente()) {
            this.verModalPago = true;
          } else {
            this.verModalActivacion = true;
          }
        } else {
          this.modalIniciarSesion();
        }
      })

    },
    modalIniciarSesion() {
      EventBus.$emit("modal-login", true);
    },
    eliminarCertificado(certificado) {
      if (certificado.id_tipo_tramite == ID_TIPO_TRAMITE.ESTUDIO_TITULO) {
        this.lista_carro = this.lista_carro.filter(f => {
          return f.titulo_referencial != certificado.titulo_referencial
        })

      } else {
        let eliminado = this.lista_carro.splice(this.lista_carro.indexOf(certificado), 1)[0];

        if (eliminado.id_tipo_registro_FK == ID_TIPO_REGISTRO.INSCRIPCION_PROPIEDAD) {


          let id;
          switch (certificado.id_tipo_certificado) {
            case ID_TIPO_CERTIFICADO.INSCRIPCION_DE_PROPIEDAD:
              id = ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA;
              break;
            case ID_TIPO_CERTIFICADO.INSCRIPCION_DE_HIPOTECA:
              id = ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_HIPOTECA;
              break;
            case ID_TIPO_CERTIFICADO.INSCRIPCION_DE_PROHIBICION:
              id = ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_PROHIBICION;
              break;
            default:
              id = null;
          }

          console.log("id: ", id)
          if (id != null) {
            let copia = this.lista_carro.find(f => {
              return f.id_tipo_registro_FK == ID_TIPO_REGISTRO.INSCRIPCION_PROPIEDAD && f.id_tipo_certificado == id;
            })

            if (copia.numero_copias > eliminado.numero_copias) {
              copia.numero_copias = parseInt(copia.numero_copias) - parseInt(eliminado.numero_copias);
            } else {
              this.lista_carro.splice(this.lista_carro.indexOf(copia), 1);
            }
          }

        }
      }
      carroEntity.updateCarro(this.lista_carro).then();

    },
    aumentarCantidad(item) {

      if (!item.solo_valija_electronica) {
        this.lista_carro[this.lista_carro.indexOf(item)].numero_copias++;
        carroEntity.updateCarro(this.lista_carro).then(() => {
          this.carro = carroEntity.obtenerCarroActualCategorizado()
        });
      }
    },

    reducirCantidad(item) {
      if (item.numero_copias > 1) {
        this.lista_carro[this.lista_carro.indexOf(item)].numero_copias--;
        carroEntity.updateCarro(this.lista_carro).then(() => {
          this.carro = carroEntity.obtenerCarroActualCategorizado()
        });
      }
    },

    vaciarCarro() {
      this.verModalVaciarCarro = false;
      carroEntity.updateCarro([]).then(() => {
        this.carro = carroEntity.obtenerCarroActualCategorizado();
      })
    },
    updateCarro(dir) {

      carroEntity.obtenerCarro().then(() => {
        this.carro = carroEntity.obtenerCarroActualCategorizado();
        window.location = dir;
      })
    },
    irATramites() {
      this.$router.push("/tramites");
    }

  },
  watch: {
    getCarroActual() {
      this.lista_carro = this.getCarroActual;
      this.carro = carroEntity.obtenerCarroActualCategorizado()
    }
  },
  computed: {
    soloUnEstudioDeTituloBoolean() {
      let tipo_registro = this.carro.find(carrito => carrito.id_tipo_registro == 24) // 24 es estudio de título
      console.log(tipo_registro)
      console.log(this.puedePagar, "puede pagar?")
      if (this.puedePagar) {
        if (tipo_registro == undefined) return false
        else {
          if (tipo_registro.grupos_documentos[0].certificados.length > 1) {
            return true
          } else {
            return false
          }
        }
      } else return false
    },
    getListaObservaciones() {
      let lista = [];
      this.carro.map(registro => {

        let id_tipo_registro = registro.id_tipo_registro;
        registro.grupos_documentos.map(grupo => {
          lista.push({
            id_tipo_registro: id_tipo_registro,
            id_area_agrupacion: grupo.id_area_agrupacion,
            observacion: grupo.observaciones == null ? "" : (grupo.observaciones.length > 1000 ? grupo.observaciones.substring(0, 1000) : grupo.observaciones)
          })
        })

      })

      return lista;
    },
    getTotalCarro() {
      let total = 0
      this.carro.map(item => {
        total += item.subtotal;
      })
      return total;
    },
    getCarroActual() {
      return carroEntity.obtenerCarroActual();
    },
    puedePagar() {
      return this.carro.length > 0;
    },
    hasDocumentosPorCotizar() {

      let has_por_cotizar = this.lista_carro.find(item => {
        return item.precio == 0;
      }) != null
      console.log(has_por_cotizar);


      return has_por_cotizar;
    }
  }
}
</script>

<style scoped>

</style>
