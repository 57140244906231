<template>
  <v-dialog max-width="800" v-model="ver" persistent>
    <v-card>
      <v-alert
          border="left"
          colored-border
          :type="tipo"
          class="mb-0 px-8"
          :icon="false"
      >

        <p class="text-center font-weight-bold info--text">Modificaciones en los términos y condiciones de uso</p>

        <p class="text-justify">Recientemente se han modificado nuestros términos y condiciones de uso. Puede revisar estos haciendo <a target="_blank" href="/informacion/tyc">click aquí.</a></p>

        <p class="text-center info--text">Si no acepta los nuevos términos y condiciones de uso, no podrá realizar compras en
          nuestro portal web, gracias por su comprensión</p>

        <v-row>
          <v-col cols="12" md="4">
            <v-btn @click="hideDialog" color="red" outlined block>No acepto</v-btn>
          </v-col>
          <v-col cols="12" md="8">
            <v-btn @click="updateTerminosCliente" block color="info">Acepto los términos y condiciones de uso</v-btn>
          </v-col>
        </v-row>
        <slot/>
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import usuarioEntity from "@/model/usuario/usuario.entity";

export default {
  name: "DialogTermCond",
  props: {
    time: {default: null},
    tipo: {default: "info"},
    verDialog: {default: false}
  },
  data() {
    return {
      ver: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    updateTerminosCliente() {
      usuarioEntity.updateTerminosYCondicionesUsuario().then(() => {
        this.hideDialog();
      });
    }
  }
}
</script>

<style scoped>

</style>
