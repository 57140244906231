<template>
  <v-dialog max-width="570" v-model="ver" persistent>
    <v-card>
      <v-card-text>
        <v-row v-if="!generandoSolicitudes">
          <v-col cols="12" class="pb-0">
            <modal-pago-medios-pago :ocultar-opciones="tipos_pagos_deshabilitados"
                                    :precio_total_carro="subtotal"
                                    :has_por_cotizar="has_por_cotizar"
                                    @updateTipoPago="updateTipoPago($event)"></modal-pago-medios-pago>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="pb-0">
            <div v-if="hasValija">
              <modal-pago-forma-retiro @updateCorreoValija="updateCorreoValija($event)"></modal-pago-forma-retiro>
              <v-divider></v-divider>
            </div>
            <div v-if="hasPlano">
              <modal-pago-forma-retiro-planos :comunas="comunas"
                                              @updateTipoRetiro="updateTipoRetiro($event)"
                                              @tipoRetiroSeleccionado="setTipoRetiro($event)"></modal-pago-forma-retiro-planos>
            </div>
          </v-col>
          <v-col cols="12" class="pb-0">
            <modal-pago-datos-boleta :comunas="comunas"
                                     @updateClienteBoleta="updateClienteBoleta($event)"></modal-pago-datos-boleta>

            <v-divider class="mb-3"></v-divider>
          </v-col>

          <v-col cols="12" sm="6" class="pl-7 pt-0">
            <p class="font-sm mb-0">Subtotal: <span v-if="subtotal > 0">{{ subtotal | clp }}</span>
              <span v-if="subtotal == 0">Por cotizar</span>
            </p>
            <p class="font-sm mb-0" v-if="tipo_retiro == ID_TIPO_RETIRO.CHILEXPRESS">Envío:
              {{ PRECIO_CHILEXPRESS | clp }}</p>

            <v-fade-transition>
              <p class="font-sm mb-0" v-if="!load.tipos_pagos && dataFormulario.id_tipo_pago != null">Cargos
                operacionales: {{ comision | clp }}</p>
              <p class="font-sm mb-0" v-if="load.tipos_pagos && dataFormulario.id_tipo_pago != null">Calculando...</p>

            </v-fade-transition>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex justify-sm-end pl-7 pl-sm-0 pr-7 pt-0">
            <p class="font-sm mb-0 mt-auto font-weight-bold" style="font-size: 16px">Total:
              <span v-if="precio_total_carro == 0">Por cotizar</span>
              <span v-if="precio_total_carro > 0">{{ precio_total_carro | clp }}</span>
            </p>
          </v-col>
          <v-col cols="6"  class="text-right">

            <v-btn color="grey" outlined class="mr-3" @click="hideDialog()" :disabled="generandoSolicitudes " block>
              <v-icon small>mdi-close</v-icon>
              Volver
            </v-btn>

          </v-col>

          <v-col cols="6" class="text-right">
            <v-btn color="success" outlined class="px-10" :disabled="!valido || load.tipos_pagos" block
                   @click="enviarFormulario()"
                   :loading="generandoSolicitudes">

              <span v-if="subtotal === 0">
                <v-icon class="pr-1" small>mdi-file</v-icon>Cotizar
              </span>
              <span v-if="subtotal > 0">
                <v-icon class="pr-1" small>mdi-credit-card-multiple-outline</v-icon>Pagar
              </span>
            </v-btn>
            <!--            <form ref="form" :action="transbank_url" method="POST" hidden>-->
            <!--              <input type="hidden" name="token_ws" :value="transbank_token">-->
            <!--              <input type="submit" ref="rsubmit" value="Ejecutar Pago con Webpay">-->
            <!--            </form>-->
            <p class="text-center font-md error--text mb-0" v-if="errorFormulario != null">{{ errorFormulario }}</p>
          </v-col>
        </v-row>
        <v-fade-transition>
          <v-row v-show="generandoSolicitudes">

            <modal-pago-pantalla-carga
                :tipo_pago_seleccionado="dataFormulario.id_tipo_pago"></modal-pago-pantalla-carga>
          </v-row>
        </v-fade-transition>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalPagoMediosPago from "@/views/index/carro/modal-pago/ModalPagoMediosPago";
import ModalPagoFormaRetiro from "@/views/index/carro/modal-pago/ModalPagoFormaRetiro";
import ModalPagoFormaRetiroPlanos from "@/views/index/carro/modal-pago/ModalPagoFormaRetiroPlanos";
import ModalPagoDatosBoleta from "@/views/index/carro/modal-pago/ModalPagoDatosBoleta";
import usuarioEntity from "@/model/usuario/usuario.entity";
import carroEntity, {ID_TIPO_PAGO, ID_TIPO_RETIRO, PRECIO_CHILEXPRESS} from "../../../../model/carro/carro.entity";
import ModalPagoPantallaCarga from "@/views/index/carro/modal-pago/ModalPagoPantallaCarga";

/**
 * En este componente se distribuyen todas las funciones y opciones para poder generar los pagos
 * es importante saber que éste modal solo aparece y debe aparecer cuando el cliente ha iniciado sesión
 */

export default {
  name: "ModalPago",
  components: {
    ModalPagoPantallaCarga,
    ModalPagoDatosBoleta, ModalPagoFormaRetiroPlanos, ModalPagoFormaRetiro, ModalPagoMediosPago
  },
  props: {
    verDialog: {default: false},
    time: {default: null},
    subtotal: {default: 0},
    has_por_cotizar: {default: false},
    listaObservaciones: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ver: false,
      comunas: [],
      isLoading: false,
      formularioValido: false,
      dataFormulario: {},
      valido: false,
      lista_carro: carroEntity.obtenerCarroActualCategorizado,
      errorFormulario: null,
      precio_total_carro: 0,
      comision: 0,
      tipo_retiro: null,
      ID_TIPO_RETIRO: ID_TIPO_RETIRO,
      ID_TIPO_PAGO: ID_TIPO_PAGO,
      generandoSolicitudes: false,
      transbank_url: '',
      transbank_token: '',
      tipos_pagos: [],
      load: {
        tipos_pagos: false
      },
      PRECIO_CHILEXPRESS: PRECIO_CHILEXPRESS,
      tipos_pagos_deshabilitados: []
    }
  },

  created() {
    this.getTiposPagos();
    this.isLoading = true
    usuarioEntity.getAllComunasDeRegiones(this.search).then(response => {

      this.comunas = response;

      this.comunas.map(item => {
        item.descripcion = item.comuna + ", " + item.region
      })
    }).catch(error => {
      console.log("Error: ", error)
    }).finally(() => (this.isLoading = false))
  },
  computed: {
    hasPlano() {
      return carroEntity.isFormPlanos()
    },
    hasValija() {
      return carroEntity.isFormValija()
    },

  },
  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    },
    tipo_retiro() {
      this.getPrecioTotal();
    },
    subtotal() {
      this.getPagosDeshabilitados();
    }
  },

  methods: {
    getTiposPagos() {
      this.load.tipos_pagos = true;
      carroEntity.getTiposPagos().then(res => {
        this.load.tipos_pagos = false;
        this.tipos_pagos = res;
        this.getPagosDeshabilitados()
      })
    },
    getPagosDeshabilitados() {
      this.tipos_pagos_deshabilitados = [];
      let tot = this.subtotal;
      if (this.tipo_retiro == ID_TIPO_RETIRO.CHILEXPRESS) {
        tot = this.subtotal + PRECIO_CHILEXPRESS;
      }
      if (tot > 250000) {
        this.tipos_pagos.map(t => {
          if (t.id_tipo_pago != ID_TIPO_PAGO.REDCOMPRA && t.id_tipo_pago != ID_TIPO_PAGO.TGR) {
            this.tipos_pagos_deshabilitados.push(t);
          }
        });
      }
    },
    setTipoRetiro(tipo_retiro) {
      this.tipo_retiro = tipo_retiro;
    },
    isFormularioValido() {
      if (this.dataFormulario == null) {

        return false;
      }
      if (this.dataFormulario.id_tipo_pago == null) {
        return false
      }
      if (this.hasPlano) {
        if (this.dataFormulario.retiro_planos == null) {
          return false
        }
      }

      if (this.hasValija) {
        if (this.dataFormulario.retiro_valija == null) {
          return false
        }
      }

      if (this.dataFormulario.cliente_boleta == null) {
        return false
      }

      return true
    },
    getPrecioTotal() {
      let tot = this.subtotal;
      if (this.tipo_retiro == ID_TIPO_RETIRO.CHILEXPRESS) {
        tot = this.subtotal + PRECIO_CHILEXPRESS;
      }

      let comision = this.tipos_pagos.find(t => t.id_tipo_pago === parseInt(this.dataFormulario.id_tipo_pago)).comision;
      tot = parseInt(tot * comision)

      this.precio_total_carro = tot
      this.getComision();
    },
    getComision() {

      let tot = this.subtotal;
      if (this.tipo_retiro == ID_TIPO_RETIRO.CHILEXPRESS) {
        tot = this.subtotal + PRECIO_CHILEXPRESS;
      }

      let comision = this.tipos_pagos.find(t => t.id_tipo_pago === parseInt(this.dataFormulario.id_tipo_pago)).comision;
      this.comision = parseInt(tot * (comision - 1))
    },
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    updateTipoPago(id_tipo_pago) {
      this.dataFormulario.id_tipo_pago = id_tipo_pago;
      this.valido = this.isFormularioValido();
      this.getPrecioTotal();
    },
    updateTipoRetiro(obj_direccion) {
      this.dataFormulario.retiro_planos = obj_direccion;
      this.valido = this.isFormularioValido();
      this.getPrecioTotal();
    },
    updateClienteBoleta(obj_cliente_boleta) {
      this.dataFormulario.cliente_boleta = obj_cliente_boleta;
      this.valido = this.isFormularioValido();
    },
    updateCorreoValija(obj_valija) {
      this.dataFormulario.retiro_valija = obj_valija;
      this.valido = this.isFormularioValido();
    },


    /**
     * Este método envía todo el conjunto de información para poder generar el pago de la solicitud cliente
     */
    enviarFormulario() {
      this.generandoSolicitudes = true;
      this.$emit("generandoCarro", null);
      this.dataFormulario.observaciones_solicitudes = this.listaObservaciones;

      carroEntity.validarFormularioPago(this.dataFormulario).then(resp => {

        if (resp.status) {

          if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.REDCOMPRA) {

            this.transbank_url = resp.obj.url;
            this.transbank_token = resp.obj.token_ws;

            this.$emit("updateCarro", resp.obj.url + '?token_ws=' + resp.obj.token_ws);

          } else {
            this.$emit("updateCarro", resp.obj);
          }


        } else {
          this.errorFormulario = "Ocurrió un error inesperado..."
        }
      }).catch(error => {
        this.errorFormulario = error.mensaje;
      }).finally(() => {
        /*this.generandoSolicitudes = false;*/
      });
    }
  }
}
</script>

<style scoped>

</style>
