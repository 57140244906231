<template>
  <v-row>
    <v-col class="pb-0" cols="12"><h3 class="text-center font-weight-medium">¿Cómo retirará los Planos?</h3></v-col>
    <v-col class="py-0" cols="12">
      <v-radio-group
          v-model="tipo_retiro"
          class="mt-2"
          row

      >
        <v-row>
          <v-col class="d-flex align-center justify-center pb-0" cols="6" @click="tipo_retiro=ID_TIPO_RETIRO.PRESENCIAL">
            <v-radio
                class="mr-0"
                :value="ID_TIPO_RETIRO.PRESENCIAL"
                label=""
            >
            </v-radio>
            <img alt="" :src="require('@/assets/img/cbrls-logo-gray.svg')" style="width: 100px "/>
          </v-col>
          <v-col class="d-flex align-center justify-center pb-0" cols="6" @click="tipo_retiro=ID_TIPO_RETIRO.CHILEXPRESS">
            <v-radio
                class="mr-0"
                :value="ID_TIPO_RETIRO.CHILEXPRESS"
                label=""
            >
            </v-radio>
            <img alt="" src="../../../../assets/img/modal-pago/logo_chilexpress.png" style="width: 100px"/>
          </v-col>

        </v-row>
      </v-radio-group>

    </v-col>

      <v-col cols="12" v-if="tipo_retiro === ID_TIPO_RETIRO.PRESENCIAL" class="pt-0" style="font-size: 12px">
        <p class="mb-0 text-center">El retiro presencial no tiene ningún costo asociado y podrá ser realizado durante las
          horas
          de atención a público.</p>
      </v-col>



      <v-col v-if="tipo_retiro == ID_TIPO_RETIRO.CHILEXPRESS && !modificarDireccion" cols="12">

        <h3 class="pb-5 font-weight-medium text-center">Los planos serán enviados a la siguiente dirección:</h3>

        <p class="text-center mb-0 pa-3 " style="border: gray 1px solid">{{ direccion }}</p>

        <div class="text-center pt-2">
          <v-btn x-small color="info" @click="modificarDireccion = true">
            <v-icon small class="pr-1">mdi-map-marker-radius-outline</v-icon>
            Modificar dirección
          </v-btn>
        </div>

      </v-col>



    <v-fade-transition>
      <v-col cols="12" v-if="tipo_retiro == ID_TIPO_RETIRO.CHILEXPRESS && modificarDireccion">

        <p class="font-md font-weight-medium mt-3 mb-2">Ingrese la dirección de envío</p>
        <v-row>
          <v-col cols="12">
            <v-autocomplete class="text-sm" style=""
                            :autofocus="!$vuetify.breakpoint.mobile"
                            dense
                            outlined
                            v-model="comuna"
                            :items="items"
                            :search-input.sync="search"
                            hide-no-data
                            hide-details="auto"
                            hide-selected
                            item-text="Description"
                            item-value="API"
                            label="Comuna"
                            placeholder="Ej: La Serena, Coquimbo"
                            return-object
                            :error-messages="errorComuna"
                            @blur="$v.comuna.$touch()"

            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
                label="Dirección"
                placeholder=" "
                outlined
                dense
                hide-details="auto"
                v-model="direccion"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-fade-transition>
  </v-row>

</template>

<script>
import {ID_TIPO_RETIRO} from "@/model/carro/carro.entity";
import {required} from "vuelidate/lib/validators";
import usuarioEntity from "../../../../model/usuario/usuario.entity";

export default {
  name: "ModalPagoFormaRetiroPlanos",
  props: {
    comunas: {default: []}
  },
  data() {
    return {
      tipo_retiro: null,
      ID_TIPO_RETIRO: ID_TIPO_RETIRO,
      modificarDireccion: false,
      search: null,
      comuna: null,
      direccion: usuarioEntity.getDireccionUsuario()

    }

  },
  validations: {
    comuna: {required},
  },
  methods: {
    emitValidacion() {
      let obj = {id_tipo_retiro: this.tipo_retiro, direccion: usuarioEntity.getDireccionUsuario()}
      if (this.id_tipo_retiro == ID_TIPO_RETIRO.CHILEXPRESS) {
        obj.direccion = this.direccion;
        if (this.modificarDireccion) {
          if (this.direccion === null || this.direccion.trim() === "") {
            obj = null;
          }
          if (this.comuna != null) {
            obj.comuna = this.comuna
          } else {
            obj = null;
          }
          //TODO Al momento de enviar la solicitud, es necesario actualizar la dirección del cliente
        }

      }
      this.$emit("updateTipoRetiro", obj)
    }
  },
  watch: {
    comuna() {
      this.emitValidacion()
    },
    modificarDireccion() {
      if (this.modificarDireccion) {
        this.emitValidacion();
      }
    },
    tipo_retiro() {
      this.emitValidacion()
      this.$emit("tipoRetiroSeleccionado", this.tipo_retiro)
    },
    direccion() {
      this.emitValidacion()
    }

  },
  computed: {
    items() {
      return this.comunas.map(entry => {
        const Description = entry.comuna + ", " + entry.region

        return Object.assign({}, entry, {Description})
      })
    },
    errorComuna() {
      const errors = [];
      if (!this.$v.comuna.$dirty) return errors;
      !this.$v.comuna.required && errors.push("Ingrese la comuna en la cual reside");
      return errors;
    },
  }
}
</script>

<style scoped>

</style>
