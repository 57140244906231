<template>
  <v-container class="custom-container" >
    <v-fade-transition mode="out-in">
      <v-row :class="{'ocultar': generandoCarro}">
        <v-col cols="12">
          <v-alert
              border="left"
              color="cyan"
              outlined

              type="success"
              :icon="false"
          >
            <div class="d-flex align-center align-items-center">
              <div class="d-flex">
                <v-icon color="cyan" class="px-1">mdi-cart</v-icon>
                <h3 class="font-weight-regular">Carro de compras</h3>

              </div>
              <div class="float-right ml-auto">

              </div>

            </div>

          </v-alert>
        </v-col>
        <v-col cols="12" class="pt-0">
          <carro-view-lista @generandoCarro="generandoCarro = true"></carro-view-lista>
        </v-col>
        <v-col>

        </v-col>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
import CarroViewLista from "@/views/index/carro/CarroViewLista";

/**
 * Informacion sobre el carro de compras:
 *
 * El carro siempre se trabaja con lo que esté almacenado en los datos de Vuex, exista o no sesión
 * En caso deque exista sesión, se ejecuta una recarga del carro desde el componente del header, y se envían los
 * datos obtenidos a Vuex.
 *
 * De igual modo, cada vez que existan actualizaciones en el carro, se ejecutará la recarga del carro en Vuex
 */

export default {
  name: "CarroView",
  components: {CarroViewLista},
  data(){
    return{
      generandoCarro: false
    }

  }
}
</script>

<style scoped>
.ocultar{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}
</style>
