<template>
  <div>

    <v-row class="d-none d-sm-flex" style="color: black">
      <v-col cols="12" class="py-0 px-0">
        <h3 class="pl-2 info--text font-weight-medium pb-3">{{ grupo.area_agrupacion }}</h3>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="6" class="py-1 pl-0">
        <p class="mb-0 font-weight-medium pl-2">Documentos</p>
      </v-col>
      <v-col cols="2" class="py-1">
        <p class="mb-0 font-weight-medium text-center">Índice</p>
      </v-col>
      <v-col cols="2" class="py-1">
        <p class="mb-0 font-weight-medium text-center">Cantidad</p>
      </v-col>
      <v-col cols="2" class="py-1 pr-0">
        <p class="mb-0 font-weight-medium text-center">Precio</p>
      </v-col>
      <v-col cols="12" class="py-0 px-0 hoverable-2" v-for="(certificado,index) in grupo.certificados" :key="index">

        <v-divider></v-divider>
        <v-row>
          <v-col cols="6" class="py-2">
            <div class="mb-0 pl-2 pr-1 d-flex align-center" style="line-height: 17px">
              <div class="pr-2" >
                <v-btn
                    text
                    icon
                    color="red"
                    small
                    class="pa-0"
                    :class="{noPuedeEliminar: (certificado.id_tipo_registro_FK === 17 && certificado.id_area_agrupacion === 36) || (certificado.id_tipo_registro_FK === 17 && certificado.id_area_agrupacion === 34)}"
                    @click="deleteItemCarro(certificado)"
                >
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
              <div>
                {{ certificado.tipo_certificado }} <br><small v-show="certificado.notas!=''">{{certificado.notas}}</small>
                <div v-if="certificado.vigencia != -1">({{ certificado.vigencia }} Años)</div>
                <div class="" v-if="certificado.id_tipo_tramite == ID_TIPO_TRAMITE.ESTUDIO_TITULO">
                  <small class="font-weight-light">
                    Estudio a 10 años sobre el título {{ certificado.titulo_referencial }}
                  </small>
                </div>
              </div>


            </div>
          </v-col>
          <v-col cols="2" class="d-flex align-center justify-center py-2">{{ certificado.indice }}</v-col>
          <v-col cols="2" class="d-flex align-center justify-center py-2">
            <v-btn class="px-0" style="min-width: 25px"
                   small
                   elevation="0"
                   height="26"
                   tile
                   outlined
                   color="primary"
                   @click="reducir(certificado)"
            >
              <v-icon small dark>
                mdi-minus
              </v-icon>
            </v-btn>
            <input type="text"
                   class="input-spin"
                   disabled
                   v-model="certificado.numero_copias"
            >
            <v-btn
                outlined
                class="px-0" style="min-width: 25px"
                small
                elevation="0"
                height="26"
                tile
                color="primary"
                @click="aumentar(certificado)"
            >
              <v-icon small dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2" class="d-flex align-center justify-center py-2">{{ certificado.precio | clp }}</v-col>
        </v-row>

      </v-col>

    </v-row>
    <v-row class="d-flex d-sm-none">
      <v-col cols="12" class="py-0 px-0">
        <h3 class="pl-2 info--text font-weight-medium pb-3">{{ grupo.area_agrupacion }}</h3>
      </v-col>

      <v-col cols="12" class="py-0 px-0 " v-for="(certificado,index) in grupo.certificados" :key="index">

        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" class="py-2 text-center">
            <p class="mb-0 pl-2 pr-1 ">
              <v-btn text icon color="red" small class="pa-0" @click="deleteItemCarro(certificado)">
                <v-icon small>mdi-trash-can-outline</v-icon>
              </v-btn>
              {{ certificado.tipo_certificado }} <span v-if="certificado.vigencia != -1">({{ certificado.vigencia }} Años)</span>
            </p>
          </v-col>
          <v-col cols="6"><p class="font-weight-medium mb-1">Índice:</p></v-col>
          <v-col cols="6" class="text-right"><p class="mb-1">{{ certificado.indice }}</p></v-col>
          <v-col cols="6"><p class="font-weight-medium mb-2">Cantidad:</p></v-col>
          <v-col cols="6" class="text-right">
            <v-btn class="px-0" style="min-width: 25px"
                   small
                   elevation="0"
                   height="26"
                   tile
                   outlined
                   color="primary"
                   @click="reducir(certificado)"
            >
              <v-icon small dark>
                mdi-minus
              </v-icon>
            </v-btn>
            <input type="text"
                   class="input-spin"
                   disabled
                   v-model="certificado.numero_copias"
            >
            <v-btn
                outlined
                class="px-0" style="min-width: 25px"
                small
                elevation="0"
                height="26"
                tile
                color="primary"
                @click="aumentar(certificado)"
            >
              <v-icon small dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6"><p class="font-weight-medium mb-0">Total:</p></v-col>
          <v-col cols="6" class="text-right"><p class="mb-0">{{ certificado.precio | clp }}</p></v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import {ID_TIPO_TRAMITE} from "@/model/tramites/tramites.entity";

export default {
  name: "ItemCarro",
  props: {
    grupo: {
      default: null
    }
  },
  data() {
    return {
      ID_TIPO_TRAMITE: ID_TIPO_TRAMITE
    }

  },
  methods: {
    deleteItemCarro(item) {
      this.$emit("deleteItemCarro", item)
    },
    aumentar(item) {
      this.$emit("aumentarCantidad", item);
    },
    reducir(item) {
      this.$emit("reducirCantidad", item);
    }
  }

}
</script>

<style scoped>
.hoverable-2:hover {
  background-color: #80808014;
  transition: background-color 0.3s linear;
}
.noPuedeEliminar{
  pointer-events: none;
  color: white;
}
</style>
